const shieldsData = [
  {
    id: 1,
    image: 'src/test.jpg',
    name: 'Diamante 3',
    status: 'A',
    points: 30000,
    sequence: 1,
  }, {
    id: 2,
    image: 'src/test.jpg',
    name: 'Diamante 2',
    status: 'I',
    points: 25000,
    sequence: 2,
  }, {
    id: 3,
    image: 'src/test.jpg',
    name: 'Diamante 1',
    status: 'A',
    points: 20000,
    sequence: 3,
  }, {
    id: 4,
    image: 'src/test.jpg',
    name: 'Ouro 3',
    status: 'A',
    points: 15000,
    sequence: 4,
  }, {
    id: 5,
    image: 'src/test.jpg',
    name: 'Ouro 2',
    status: 'I',
    points: 10000,
    sequence: 4,
  },
]

export { shieldsData };
