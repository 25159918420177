<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { shieldsData } from "./shields-data";

/**
 * Gamefication Shields component
 */
export default {
  page: {
    title: "Escudos Gameficação",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      shieldsData: shieldsData,
      title: "Escudos Gameficação",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Escudos Gameficação",
          active: true,
        },
      ],
      showModal: false,
      fieldAliases: {
        id: "#",
        image: "Foto",
        name: "Nome",
        status: "Status",
        points: "Pontos",
        level: "Nível",
      },
      formChange: {
        id: "",
        title: "",
        image: "",
      },  
    };
  },
  methods: {
    changeShield(open) {
      this.showModal = open;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-start flex-wrap">    
          <div 
            class="col-lg-6 col-xl-3 px-3"            
            v-for="(shield) in shieldsData"
            :key="shield.id">
            <b-card no-body class="shadow-sm p-3 mb-5 bg-white rounded">
              <b-card-body>
                <b-card-title>
                  <h5 class="card-title">{{shield.name}}</h5>
                </b-card-title>
              </b-card-body>
              <img src="@/assets/images/small/img-4.jpg" class="img-fluid" />
              <b-card-body>
                <b-card-text class="row">
                  <h4 class="col-8">{{ shield.points }} Pontos</h4>
                  <div class="col">
                    <span
                      class="badge ms-1 w-100 h-100 d-flex align-items-center justify-content-center"
                      :class="
                        shield.status == 'A' ? 'bg-success' : 'bg-warning'
                      "
                      ><h5 class="m-0">{{ shield.status == "A" ? "Ativo" : "Inativo" }}</h5></span
                    >
                  </div>
                </b-card-text>
                <div class="button-items">
                    <button type="button" class="btn btn-secondary" @click="changeShield(true)" title="Editar titulo"><i class="fas fa-edit"></i></button>
                    <button type="button" class="btn btn-secondary" @click="changeShield(true)" title="Editar foto do escudo"><i class="fas fa-camera"></i></button>
                    <button type="button" class="btn btn-secondary" title="Ativar escudo"><i class="fas fa-thumbs-up"></i></button>
                    <button type="button" class="btn btn-secondary" title="Desativar escudo"><i class="fas fa-thumbs-down"></i></button>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
      </div>
    </div>    
    <!-- end row -->

    <b-modal v-model="showModal" id="modal-dialog" title="Editar Escudo" title-class="font-18" hide-footer>
      <form class="needs-validation" @submit.prevent="formSubmit">       
        <div class="row mb-4">
          <div class="col">        
            <label class="form-label" for="title">Título</label>
            <div class="input-group">         
              <input
                id="cod"
                v-model="formChange.title"
                type="text"
                class="form-control"
                placeholder="Digite o título do Escudo"
              /> 
            </div>
          </div>
        </div>     
        <div class="mt-2 text-end">
            <b-button variant="light" @click="changeShield(false)">Fechar</b-button>
            <b-button type="submit" variant="info" class="ms-1"
              >Atualizar</b-button
            >
          </div>
      </form>    
    </b-modal>
  </Layout>
</template>
